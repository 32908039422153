import React from 'react'

export default function Footer() {
    return (
        <div class="sec-fixed-footer">
		<a href="https://app.instacrypto.com" class="footer-login">Login</a>
		<div class="contents desktop-view container">
			<p class="p-content">Follow <a href="https://www.instagram.com/instacryptotv/" target="_blank">@InstaCrypto.TV</a> On <i class="fa fa-instagram" aria-hidden="true"></i> For A Chance To Win Crypto Everyday 
			</p>
		</div>
		<div class="contents mobile-view">
			<p class="p-content"><a href="https://www.instagram.com/instacryptotv/" target="_blank"> Follow Us On <i class="fa fa-instagram" aria-hidden="true"></i> To Win Free Crypto</a>
			</p>
		</div>
	</div>
    )
}

import React,{useState,useEffect} from 'react'
import axios from 'axios'
import './CurrencyDashboard.scss'
import {Table} from 'react-bootstrap'

let kamal=[]
export default function CurrencyDashboard() {
const  [Fiat,setFiat]=useState([])
const  [Fiats,setFiats]=useState([])
const [add,setadd]=useState(3)
const [zadd,setzadd]=useState(0)     
const newuseraddfunctionss = async() => {
        axios.post("https://comms.globalxchange.com/coin/vault/service/coins/get", {
          app_code:"instacrypto",
        })
          .then(async res => {
      
            if (res.data.status) {
              setFiat(res.data.coins_data)
          
              let g = res.data.coins_data;
              let size = 0, key; 

          for (key in res.data.coins_data) {
            let coinImage = res.data.coins_data[key].coinImage;
            let coinName = res.data.coins_data[key].coinName;
            let coinSymbol = res.data.coins_data[key].coinSymbol;
           fetch(`https://comms.globalxchange.com/coin/vault/service/payment/stats/get?to_currency=${coinSymbol}`)
            .then(async(res) => res.json())
              .then(async(prodData) => {
                let {banker, to_currency, country, paymentMethod} = prodData.stats;

      
                
                let aje=[
                    {
                        coinName:coinName,
                        coinImage:coinImage,
                        to_currency:to_currency,
                        country:country,
                  
                        paymentMethod:paymentMethod,
                    }
                ]
                let s=[]
           await Fiats.push(...aje);
               

let pp = Fiats.filter( (ele, ind) => ind === Fiats.findIndex( elem => elem.coinName === ele.coinName && elem.coinName === ele.coinName))
await setFiats(pp)

                var children = s.concat(aje);

                { 
                }  
                let dasd=Object.assign({},aje)
           
                 })
          }
           
            }
            else {
    
    
    
            }
          })
    
    
    
      }




      
const addnext=()=>{
    if(add<=Fiat.length)
    {
        setadd(add + 3)
        setzadd(zadd + 3)
    }
}
const minsnext=()=>{
    if(zadd>0)
    {
        setadd(add - 3)
        setzadd(zadd - 3)
    }
}
      useEffect(() => {
        newuseraddfunctionss()
          return () => {
             
          }
      }, [])
 
    return (
        <div className="CurrencyDashboard " id="assets" >
            {/* <div id="google_translate_element"></div> */}
            <div className="container">

      
            <h1>Get These Assets Instantly</h1>
            <p>Can’t Find The Asset That You Are Looking For? <span>Click Here To Add It </span></p>
            
            <div>
            <Table  bordered className="table-responsive" >
  <thead>
    <tr>
      <th></th>
      <th>Countries</th>
      <th>Methods</th>
      <th>Bankers</th>
    </tr>
  </thead>
  <tbody>
      
  {
                Fiats.slice(zadd,add).map(item=>{
                    return(
                             <>
                                 <tr>       
<td><img src={item.coinImage} alt=""/>{item.coinName}</td>
<td>{item.country}</td>
<td>{item.paymentMethod}</td>
<td>{item.to_currency}</td>
</tr>

                        </>
                    )
                })
            }
  

  </tbody>
</Table>
            </div>
            
            <>
<div className="divlabel">
    <label className="labelleft" onClick={minsnext}>{`<`} </label>
    <label  className="labelright" onClick={addnext}> {`>`} </label>
</div>

         
            </>
        </div>
        </div>
    )
}

import React, { Component } from 'react';

import Axios from 'axios';


export const BrokerAppContext = React.createContext();

class BrokerAppProvider extends Component {
  constructor(props) {
    super();

    this.state = {
    
      isMobile: false,
  
    };
  }

  componentDidMount() {

    window.addEventListener('resize', this.screenResizeListener);
  
    this.screenResizeListener();
  }

 

//   componentWillUnmount() {
//     window.removeEventListener('resize', this.screenResizeListener);
//   }


  screenResizeListener = () => {
    this.setState({ isMobile: window.innerWidth <= 576 });
  };

  

  render() {
    const { children } = this.props;
    return (
      <BrokerAppContext.Provider
        value={{
          ...this.state,
    
        }}
      >
        {children}
      </BrokerAppContext.Provider>
    );
  }
}

export const { Consumer } = BrokerAppContext;
export default BrokerAppProvider;

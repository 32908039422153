import React, { useEffect,useState } from 'react'

import h1  from '../img/youtube-play.svg'
import axios from 'axios'
export default function SectionOne() {
    const [result,setresult]=useState('')
    const [iresult,setiresult]=useState('')
    const imagefecting= async (e)=>{
     
        await axios
          .get(`https://comms.globalxchange.com/gxb/apps/mobile/app/links/logs/get?app_code=instacrypto`) 
          .then(res => {
            if (res.data.status) {
              
                setresult(res.data.logs[0].android_app_link)
                setiresult(res.data.logs[0].ios_app_link)
            }
    
          });
    
      }
      useEffect(() => {
        imagefecting()
          return () => {
              
          }
      }, [])
    return (
   
    
            <div class="container sec-div1">
                <div class="row">
                    <div class="col-lg-6 col-sm-12 column-1">
                        <div class="contents">
                            <div class="content-1" data-aos="fade-up" data-aos-duration="1000">
                                <p class="head-content">Connecting  <br/>You To Crypto</p>
                            </div> 
                            <div class="content-2" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                                <p class="text-content">The InstaCrypto App Allows You To Find, Analyze and Transact With Hundreds Of Bitcoin Suppliers. INSTANTLY!</p>
                            </div>
                            <div class="content-3" data-aos="fade-up" data-aos-duration="1000">
      
                            <div class="showlink d-flex" >

                                    <a class='nav-link app-link android' href={result} target='_blank'><i class='fa fa-android' aria-hidden='true'></i> Android</a><a class='nav-link app-link ios' href={iresult} target='_blank'><i class='fa fa-apple' aria-hidden='true'></i> IOS</a>

								
            </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12 column-2">
                        <div class="contents">
                            <div class="image-1">
                                <div>
                                    <div >
                                       <img src="https://drivetest.globalxchange.io/gxsharepublic/?full_link=saikrishna.brain.stream/dc81a308921b03dde91721d17d3bbd35"  />
                                       <div class="overlay">
                                        <a href="#" id="play" class="icon" title="User Profile">
                                            <img src={h1}/>
                                        </a>
                                    </div>
                                    </div>
                                    <div >
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
    )
}

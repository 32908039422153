
import React,{useEffect,useState} from 'react'
import CurrenyDashborad from './CurrencyDashboard'
import Navbar from './Navbar'
import Footer from './Footer'
import Contact from './Contact'
import Banker from './Banker'
import ReactDOM from 'react-dom'
import How from './Works'
import AOS from "aos";


import burger from '../img/burger.png'
import logomobile from '../img/logomobile.png'
import close from '../img/close.png'
import WhiteColor from '../Component/WhiteColor.svg'
import color from '../Component/Color.svg'
import Sec from './SectionOne'
import AnchorLink from 'react-anchor-link-smooth-scroll'
function Controller() {
  const [show,setshow]=useState(false)
  const [scrollState, setScrollState] = useState("white")
  const [colorscrollState, setcolorscrollState] = useState("#186AB4")
  const [toggle,settoggle]=useState(false)
  let listener = null
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  React.useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
    return () => {};
  }, []);
useEffect(() => {
  listener = document.addEventListener("scroll", e => {
    var scrolled = document.scrollingElement.scrollTop
    if (scrolled >= 10) {
     
      setshow(true)
          setScrollState("#186AB4")
          setcolorscrollState("white")
      
    } else {
      setshow(false)
        setScrollState("white")
        setcolorscrollState("#186AB4")
     
    }
  })
  return () => {
    document.removeEventListener("scroll", listener)
  }
}, [scrollState])
  return (
    <div className="App">
<div className="desshowdic">
 <div className={show?"true navbarc":"false navbarc"} style={{background:scrollState}}>
                 <div className='container inside'>

{
  show?
  <img style={{    width: "9rem"}} src={WhiteColor} alt=""/>
  :
  <img style={{    width: "9rem"}} src={color} alt=""/>
}

		
<ul style={{color:colorscrollState}}>
    <li><AnchorLink style={{color:colorscrollState}} offset={() => 100} href='#assets'>Assets</AnchorLink></li>

    <li><AnchorLink style={{color:colorscrollState}} offset={() => 100} href='#howitworks'>How It Works</AnchorLink></li>
    <li><AnchorLink style={{color:colorscrollState}} offset={() => 100} href='#bankers'>Bankers</AnchorLink></li>

</ul>
<label >I Want To Sell Crypto</label>
        </div>
        </div> 
        </div>
<div className="showdic">


        <div className="true navbarc" >
                 <div className='container inside'>
                   <img  onClick={()=>settoggle(true)} src={burger} className="burder" alt=""/>
                 <img src={logomobile} alt=""/>


{
  toggle?
<div className="asdas">
  <img onClick={()=>settoggle(false)} className="closeimg" src={close} alt=""/>
<img style={{    width: "9rem"}} src={color} alt=""/>
<ul style={{color:colorscrollState}}>
    <li><AnchorLink style={{color:colorscrollState}} offset={() => 100} href='#assets'>Assets</AnchorLink></li>

    <li><AnchorLink style={{color:colorscrollState}} offset={() => 100} href='#howitworks'>How It Works</AnchorLink></li>
    <li><AnchorLink style={{color:colorscrollState}} offset={() => 100} href='#bankers'>Bankers</AnchorLink></li>

</ul>
<label >I Want To Sell Crypto</label>
</div>
  :


  null
}

</div>

        </div>
        </div>

       <Sec/>

  
                                                                <CurrenyDashborad/>

                                                                <How/>
<div id="bankers">
<Banker />
</div>
                                                       
         <Contact/> 
      <Footer/>

        {/* <div id="google_translate_element"></div> */}

    </div>
  );
}

export default Controller;

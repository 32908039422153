import React,{useEffect} from 'react'
import copy from '../img/copy.png'
import mobilelogo from '../img/mobilelogo.png'
import android from '../img/android.png'
import ios from '../img/ios.png'
import toplogo from '../img/toplogo.png'


import step2 from '../img/step2.png'
import step3 from '../img/step3.png'
import step4 from '../img/step4.png'
import finalback from '../img/finalback.png'
import axios from 'axios'
import ReactDOM from 'react-dom';
import {useHistory} from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
// import * as animationData from "../loader.json";
// import Lottie from "react-lottie";
import './Mobilelanding.scss'
export default function Mobilelanding() {
    
   const nav=[
       {
           name:"SelectDevice",
           img:step2,
       },
       {
        name:"SelectMethod",
        img:step3,
    },
    {
        name:"SelectLocation",
        img:step4,
    },
   ] 
    
const device=[
    {
        name:"Android",
        img:android
    },
    {
        name:"Iphone",
        img:ios  
    }
]
const Methed=[
    {
        name:"Download App On This Device",
        img:android
    },
    {
        name:"Send Me The Download Link",
        img:ios  
    }
]

const location=[
    {
        name:"Copy Download Link",
        img:android
    },
    {
        name:"Open Download Link",
        img:ios  
    }
]
// const defaultOptions = {
//     loop: true,
//     autoplay: true,
//     animationData: animationData.default,
//     rendererSettings: {
//       preserveAspectRatio: "xMidYMid slice"
//     }
//   };
const [show,setshow]=React.useState("SelectDevice")
const [detial,setdetial]=React.useState([])
const [result,setresult]=React.useState("")
const [copyclip,setcopyclip]=React.useState(false)
const history = useHistory();
const finalfun=()=>{
    setcopyclip(true)
    setTimeout(function(){     setcopyclip(false); }, 4000);
}
const methodfu= async (e)=>{
if(e==="Download App On This Device")
{
    setshow("SelectLocation")
}
}
const locationfu= async (e)=>{
    if(e==="Copy Download Link")
    {
        setshow("final")
    }
    else{
        window.open(result,'_blank');
    }
    }
const imagefecting= async (e)=>{
     
    await axios
      .get(`https://comms.globalxchange.com/gxb/apps/mobile/app/links/logs/get?app_code=instacrypto`) 
      .then(res => {
        if (res.data.status) {
   if(e==="Android")
   {
    setresult(res.data.logs[0].android_app_link)
    setshow("SelectMethod")
   }
   else{
    setresult(res.data.logs[0].ios_app_link)
    setshow("SelectMethod")
   }
     
        }

      });

  }
  useEffect(() => {
 
      return () => {
          
      }
  }, [])
    const showfucn=()=>{
        switch(show){
            // case "loading":
            //     return(
            //         <Lottie
            //         options={defaultOptions}
            //       width="200px"
            //       height="200px"
            //       />
            //     )

            case "final":
                return(
                    <>
                    <div className="final">
                    <div className="topnav">
        <img src={toplogo} alt=""/>
    </div>
    <div className="divsub">
                    <h1>Download Link</h1>
{
    copyclip?
    <div  className="cipboardtrue">
    <p>Copied To Your Clipboard</p>
    </div>
    :
    <div className="cipboard">
    <p>{result}</p>
                  <CopyToClipboard text={result}>
        <span onClick={finalfun}><img src={copy} alt=""/></span>
      </CopyToClipboard>
      </div>


}

                  
</div>      
<div className="finalbotton">
<div className="subone">

    <img onClick={()=>setshow("SelectLocation")} src={finalback} alt=""/>
    </div>
    <div className="subsec">
        <h4 onClick={()=> history.push("/") }>Go To Website</h4>
    </div>
    </div>    
                    
                    </div>
                   
               
              </>
                )
            case "Home":
                return(
<div className="Home">

    
<img src={mobilelogo} alt=""/>
  <p>Welcome To InstaCrypto. Please Select One Of The Following Options</p>
  <label htmlFor="" className="download"onClick={()=> setshow("SelectDevice")}>Download Mobile App</label>
  <label htmlFor="" className="process" onClick={()=> history.push("/")}>Proceed To Website</label>
</div>
                )
                case "SelectDevice":
                return(
                    <div className="SelectDevice">
                                                                 <div className="topnav">
        <img src={toplogo} alt=""/>
    </div>
    <div className="divsub">
   
                    <h1>Select Device</h1>
                {
                device.map(item=>{
                    return(
                        <label onClick={()=>imagefecting(item.name)} >
                            <img src={item.img} alt=""/>
                            
{item.name}
                        </label>
                    )
                })

                
                }
                     
    </div>
    <div className="bottomnav">
  
                    <img src={step2} alt=""/>
                    <img src={step3} alt="" style={{opacity:"0.4"}}/>
                    <img src={step4} alt=""style={{opacity:"0.4"}}/>
        
    </div>
                    </div>
                                    )

                                    case "SelectMethod":
                                        return(
                                            <div className="SelectMethod">
                         <div className="topnav">
        <img src={toplogo} alt=""/>
    </div>
  
    <div className="divsub">
    <h1>Select Method</h1>
                                        {
                                        Methed.map(item=>{
                                            return(
                                                <label onClick={()=>methodfu(item.name)} >
                                        
                                                    
                        {item.name}
                                                </label>
                                            )
                                        })
                        
                                        
                                        } 
                                         </div>
                                         <div className="bottomnav">
  
  <img src={step2} alt="" style={{opacity:"0.4"}} onClick={()=>setshow("SelectDevice")}/>
  <img src={step3} alt="" />
  <img src={step4} alt=""style={{opacity:"0.4"}}/>



    </div>
                                 
                                            </div>
                                                            )


                                                            case "SelectLocation":
                                                                return(
                                                                    <div className="SelectLocation">
                                                                                          <div className="topnav">
        <img src={toplogo} alt=""/>
    </div>
    <div className="divsub">
                                                                    <h1>Select Location</h1>
                                                                {
                                                                location.map(item=>{
                                                                    return(
                                                                        <label onClick={()=>locationfu(item.name)}  >
                                                                
                                                                            
                                                {item.name}
                                                                        </label>
                                                                    )
                                                                })
                                                
                                                                
                                                                }
                                                                     </div>
                                                                     <div className="bottomnav">
  
  <img src={step2} alt="" style={{opacity:"0.4"}} onClick={()=>setshow("SelectDevice")}/>
  <img src={step3} alt="" style={{opacity:"0.4"}} onClick={()=>setshow("SelectMethod")} />
  <img src={step4} alt=""/>



    </div>
                                                                    </div>
                                                                                    )
        }
    }
  return (
    <div className="mobilelanding">
{
    showfucn()
}
    </div>
  )
}

import React from 'react'
import h2  from '../img/h1-1.png'
import h3  from '../img/h1-2.png'
import h4  from '../img/h1-3.png'
import h5  from '../img/h1-4.png'
export default function Works() {
    return (
        <>
        <div class="sec-div4" id="howitworks">
		<div class="container">
			<div class="contents">
				<div class="row desktop-view">
					<div class="col-lg-6 column-1">
						<p class="heading" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200"><span class="top-title mobile-view">Step 1</span><br/>Choose Country</p>
						<p class="text-content" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">There is no point of signing up with a platform that doesn't operate in your country. With the InstaCrypto App, you will never have to do that again. Simply Enter The Country You Want To Transact From & Our Engine Populates Every Option For Purchasing Crypto In That Country.</p>
						<a class="nav-link link2" data-toggle="modal" data-target="#countries" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="700">See All Countries</a>
					</div>
					<div class="col-lg-6 column-2 desktop">
						<div class="contents">
							<img src={h2}/>					
						</div>
					</div>
				</div>
				<div class="row mobile-view">
					
				</div>				
			</div>
		</div>
	</div>

	<div class="sec-div5 first">	
		<div class="container">
			<div class="contents">
				<div class="row">
					<div class="col-lg-6 column-1 desktop">
						<div class="contents">
							<img src={h3}/>					
						</div>
					</div>	
					<div class="col-lg-6 column-2 ">
						<p class="heading" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200"><span class="top-title mobile-view">Step 2</span><br/>Select Payment Method</p>
						<p class="text-content" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">Now You Can Peruse All The Payment Methods Which Are Applicable To Your Specific Country. All You Have To Do Is Choose How You Want To Pay & Let The InstaCrypto Engine Fetch All The Platforms Who Can Service Your Needs.</p>
						<a class="nav-link link2" data-toggle="modal" data-target="#payments-sec" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">Select Payment Method</a>
					</div>
				</div>
			</div>
		</div>
	</div>


  <div class="modal fade howitworks-popup" id="payments-sec">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
      
 
        <div class="modal-body">
			<div class="footer-popup">
				<table>
					<tbody id="payments">
						
					</tbody>
				</table>
		    </div>
        </div>
      </div>
    </div>
  </div>

	<div class="sec-div5">	
		<div class="container">
			<div class="contents">
				<div class="row">
					<div class="col-lg-6 column-2 ">
						<p class="heading" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200"><span class="top-title mobile-view">Step 3</span><br/>Browse Bankers</p>
						<p class="text-content" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">At This Point, All You Have To Do Is Do Some Price Shopping. Having The Full List Of Relevant Vendors Gives You The Ability To Efficiently Decide Which One Of Them Best Suits This Transaction. Gone Are The Days Of Choiceless Crypto.</p>
						<a class="nav-link link2" href="#bankers" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">See All Bankers</a>
					</div>
					<div class="col-lg-6 column-1 desktop">
						<div class="contents">
							<img src={h4}/>					
						</div>
					</div>	
				</div>
			</div>
		</div>
	</div>
    <div class="sec-div6">
		<div class="container">
			<div class="contents">
				<div class="row">
					<div class="col-lg-6 column-2 desktop">
						<div class="contents">
							<img src={h5}/>					
						</div>
					</div>
					<div class="col-lg-6 column-1">
						<p class="heading" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200"><span class="top-title mobile-view">Step 4</span><br/>Access Insta Liquidity</p>
						<p class="text-content" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">Once You Have Made Your Decision, You Can Get Live Quotes Directly From The Platform You Want To Deal With. The Quote Engine Takes All Aspects Of Your Transaction Into Consideration While Transmitting The Pricing From The Vendor.</p>
						<a class="nav-link link2" href="#" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">Get Started</a>
					</div>
				</div>				
			</div>
		</div>
	</div>
    </>
    )
}

import React,{useEffect} from 'react'
import copy from '../img/copy.png'
import mobilelogo from '../img/mobilelogo.png'
import android from '../img/android.png'
import ios from '../img/ios.png'
import toplogo from '../img/toplogo.png'


import step2 from '../img/step2.png'
import step3 from '../img/step3.png'
import step4 from '../img/step4.png'
import finalback from '../img/finalback.png'
import axios from 'axios'
import ReactDOM from 'react-dom';
import {useHistory} from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
// import * as animationData from "../loader.json";
// import Lottie from "react-lottie";
import './Mobilelanding.scss'
export default function Mobilelanding() {
    
   const nav=[
       {
           name:"SelectDevice",
           img:step2,
       },
       {
        name:"SelectMethod",
        img:step3,
    },
    {
        name:"SelectLocation",
        img:step4,
    },
   ] 
    
const device=[
    {
        name:"Android",
        img:android
    },
    {
        name:"Iphone",
        img:ios  
    }
]
const Methed=[
    {
        name:"Download App On This Device",
        img:android
    },
    {
        name:"Send Me The Download Link",
        img:ios  
    }
]

const location=[
    {
        name:"Copy Download Link",
        img:android
    },
    {
        name:"Open Download Link",
        img:ios  
    }
]
// const defaultOptions = {
//     loop: true,
//     autoplay: true,
//     animationData: animationData.default,
//     rendererSettings: {
//       preserveAspectRatio: "xMidYMid slice"
//     }
//   };
const [show,setshow]=React.useState("Home")
const [detial,setdetial]=React.useState([])
const [result,setresult]=React.useState("")
const [copyclip,setcopyclip]=React.useState(false)
const history = useHistory();

    const showfucn=()=>{
        switch(show){
            // case "loading":
            //     return(
            //         <Lottie
            //         options={defaultOptions}
            //       width="200px"
            //       height="200px"
            //       />
            //     )


            case "Home":
                return(
<div className="Home">

    
<img src={mobilelogo} alt=""/>
  <p>Welcome To The InstaCrypto. Please Select One Of The Following Options</p>
  <label htmlFor="" className="download"onClick={()=> history.push("/link")}>Download Mobile App</label>
  <label htmlFor="" className="process" onClick={()=> history.push("/")}>Proceed To Website</label>
</div>
                )
              
        }
    }
  return (
    <div className="mobilelanding">
{
    showfucn()
}
    </div>
  )
}

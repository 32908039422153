import React,{useState,useEffect} from 'react'
import axios from 'axios'
import './CurrencyDashboard.scss'
import {Table} from 'react-bootstrap'

let kamal=[]
export default function Banker() {
const  [Fiat,setFiat]=useState()
const  [Fiats,setFiats]=useState([])
const [add,setadd]=useState(3)
const [zadd,setzadd]=useState(0)     
const newuseraddfunctionss = () => {
        axios.get("https://teller2.apimachine.com/admin/allBankers").then(async res => {
           
            if (res.data.status) {
              setFiat(res.data.data)
           
              let g = res.data;
              let size = 0, key; 
              for (key in res.data.data) {
              let productName = res.data.data[key].bankerTag;
              let formData = res.data.data[key].profilePicURL;
            
           fetch(`https://comms.globalxchange.com/coin/vault/service/payment/stats/get?banker=${productName}`)
            .then(res => res.json())
              .then(async(prodData) => {
                let {banker, to_currency, country, paymentMethod} = prodData.stats;

      
            
                let aje=[
                    {
                        coinName:productName,
                        coinImage:formData,
                        to_currency:to_currency,
                        country:country,
                  
                        paymentMethod:paymentMethod,
                    }
                ]
                let s=[]
             await   Fiats.push(...aje);
          

             let pp = Fiats.filter( (ele, ind) => ind ===Fiats.findIndex( elem => elem.coinName === ele.coinName && elem.coinName === ele.coinName))
             await setFiats(pp)
             
           
                 })
          }
           
            }
            else {
    
    
    
            }
          })
    
    
    
      }



      function getUnique(array, key) {
        if (typeof key !== 'function') {
          const property = key;
          key = function(item) { return item[property]; };
        }
        return Array.from(array.reduce(function(map, item) {
          const k = key(item);
          if (!map.has(k)) map.set(k, item);
          return map;
        }, new Map()).values());
      }
      
const addnext=()=>{
    if(add<=Fiat.length)
    {
        setadd(add + 3)
        setzadd(zadd + 3)
    }
}
const minsnext=()=>{
    if(zadd>0)
    {
        setadd(add - 3)
        setzadd(zadd - 3)
    }
}
      useEffect(() => {
        newuseraddfunctionss()
        setTimeout(function() { newuseraddfunctionss(); }, 5000);
          return () => {
     
          }
      }, [])
      console.log("jhgd", Fiats)
    return (
        <div className="CurrencyDashboard " >
            {/* <div id="google_translate_element"></div> */}
            <div className="container">

      
            <h1>InstaCrypto Bankers</h1>
            <p>Want To Learn Why We Call Them Bankers?<span>Click Here To Add It </span></p>
            
            <div>
            <Table  bordered >
  <thead>
    <tr>
      <th></th>
      <th>Countries</th>
      <th>Methods</th>
      <th>Supported Assets</th>
    </tr>
  </thead>
  <tbody>
      
  {
                 Fiats.slice(zadd,add).map(item=>{
                    return(
                             <>
                                 <tr >       
<td><img src={item.coinImage} alt=""/>{item.coinName}</td>
<td>{item.country}</td>
<td>{item.paymentMethod}</td>
<td>{item.to_currency}</td>
</tr>

                        </>
                    )
                })
            }
  

  </tbody>
</Table>
            </div>
            
            <>
<div className="divlabel">
    <label className="labelleft" onClick={minsnext}>{`<`} </label>
    <label  className="labelright" onClick={addnext}> {`>`} </label>
</div>

         
            </>
        </div>
        </div>
    )
}

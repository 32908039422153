import React, { useState, lazy,useContext, useEffect,Suspense } from "react";
import AOS from "aos";
import "./App.css";
import { BrokerAppContext } from './Component/ContextApi'
import { Route, Switch, Redirect } from "react-router-dom";
import MobileLanding from './Component/Mobilelanding'
import Controller from './Component/Controller'
import Linkgenter from './Component/Appinstaller'
function App() {

  React.useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
    return () => {};
  }, []);
  const { isMobile } = useContext(BrokerAppContext);

  return (
    <React.Fragment>
      <div className="main-wrapper">
        <Switch>
        <Route
            exact
            path="/link"
            component={Linkgenter}
          />
        <Route     exact path="/Download" component={MobileLanding} />
        <Route
            exact
            path="/"
            component={Controller}
          />

           
          
        </Switch>
        {
             isMobile?
             <Redirect to="/Download" />
             :
             <Route
             exact
             path="/"
             component={Controller}
           />
           }
      </div>

    </React.Fragment>
  );
}

export default App;

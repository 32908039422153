import React from 'react'

export default function Contact() {
    return (
        <>
        <div class="sec-div12">
		<div class="background-overlay-12"></div>
		<div class="container">
			<div class="contents">
				
				<div class="row">
					<div class="col-lg-6 col-sm-12 column-1">
						<div class="heading">
							<p class="heading">Contact Us</p>
						</div>
						<div class="col-lg-12 col-sm-12 address">
							<p class="location">Customers</p>
							<p class="add email"><a href="mailto:support@instacrypto.com" target="_blank">support@instacrypto.com</a></p>
							<p class="add social-link whatsapp">
								<a href="https://api.whatsapp.com/send?phone=16476067937&text=&source=&data=" target="_blank">
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M10.0025 0H9.9975C4.48375 0 0 4.485 0 10C0 12.1875 0.705 14.215 1.90375 15.8612L0.6575 19.5763L4.50125 18.3475C6.0825 19.395 7.96875 20 10.0025 20C15.5162 20 20 15.5138 20 10C20 4.48625 15.5162 0 10.0025 0Z" fill="#4CAF50"/>
										<path d="M15.8212 14.1212C15.5799 14.8025 14.6224 15.3675 13.8587 15.5325C13.3362 15.6437 12.6537 15.7325 10.3562 14.78C7.41744 13.5625 5.52494 10.5762 5.37744 10.3825C5.23619 10.1887 4.18994 8.80123 4.18994 7.36623C4.18994 5.93123 4.91869 5.23248 5.21244 4.93248C5.45369 4.68623 5.85244 4.57373 6.23494 4.57373C6.35869 4.57373 6.46994 4.57998 6.56994 4.58498C6.86369 4.59748 7.01119 4.61498 7.20494 5.07873C7.44619 5.65998 8.03369 7.09498 8.10369 7.24248C8.17494 7.38998 8.24619 7.58998 8.14619 7.78373C8.05244 7.98373 7.96994 8.07248 7.82244 8.24248C7.67494 8.41248 7.53494 8.54248 7.38744 8.72498C7.25244 8.88373 7.09994 9.05373 7.26994 9.34748C7.43994 9.63498 8.02744 10.5937 8.89244 11.3637C10.0087 12.3575 10.9137 12.675 11.2374 12.81C11.4787 12.91 11.7662 12.8862 11.9424 12.6987C12.1662 12.4575 12.4424 12.0575 12.7237 11.6637C12.9237 11.3812 13.1762 11.3462 13.4412 11.4462C13.7112 11.54 15.1399 12.2462 15.4337 12.3925C15.7274 12.54 15.9212 12.61 15.9924 12.7337C16.0624 12.8575 16.0624 13.4387 15.8212 14.1212Z" fill="#FAFAFA"/>
									</svg>
							Whatsapp</a>
							</p>
							<p class="add social-link telegram">
								<a href="https://t.me/Instacryptochat" target="_blank">
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#039BE5"/>
										<path d="M4.57581 9.78338L14.2175 6.06588C14.665 5.90422 15.0558 6.17505 14.9108 6.85172L14.9116 6.85088L13.27 14.5851C13.1483 15.1334 12.8225 15.2667 12.3666 15.0084L9.86664 13.1659L8.66081 14.3276C8.52747 14.4609 8.41497 14.5734 8.15664 14.5734L8.33414 12.0292L12.9675 7.84338C13.1691 7.66588 12.9225 7.56588 12.6566 7.74255L6.93081 11.3476L4.46247 10.5776C3.92664 10.4076 3.91497 10.0417 4.57581 9.78338Z" fill="white"/>
									</svg>
								Telegram</a>
							</p>
							<p class="add social-link onhold">
								<a href="http://onhold.app/" target="_blank">
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M3.97049 1.99624C5.23849 1.08278 6.63235 0.466106 8.17288 0.171627C12.0589 -0.573232 16.17 1.15091 18.3284 4.4791C19.3042 5.98268 19.8978 7.62714 19.9717 9.4379C20.0098 10.3883 19.4612 10.9738 18.5039 10.9807C17.4484 10.9888 16.3917 10.99 15.3351 10.9946C14.9979 10.9888 14.6595 10.9888 14.3223 10.9761C13.6814 10.9519 13.1802 10.5281 13.0462 9.90214C12.9977 9.67579 12.9746 9.44252 12.9157 9.21848C12.5289 7.77496 11.2539 6.88459 9.72265 6.98044C8.32532 7.06821 7.14971 8.18608 6.98688 9.65039C6.90143 10.4114 6.30207 11.0108 5.48215 10.99C4.15296 10.9553 2.82145 10.9842 1.4911 10.9796C0.506035 10.9761 -0.0586717 10.3814 0.00484337 9.39171C0.0752874 8.29347 0.31549 7.23335 0.719677 6.20556C1.39756 4.48603 2.55007 3.13951 3.97049 1.99624ZM7.83106 5.35215C7.91998 5.31981 8.01005 5.28979 8.09782 5.25514C9.22954 4.80707 10.3832 4.74125 11.5369 5.13504C13.2552 5.72169 14.3823 6.89499 14.8997 8.64223C14.9436 8.79236 14.9829 8.89745 15.1861 8.89398C15.9887 8.88012 16.7913 8.88359 17.5951 8.89167C17.7879 8.89398 17.8237 8.82931 17.7902 8.64916C17.6343 7.80152 17.3745 6.98968 16.9495 6.2402C15.5568 3.78506 13.4504 2.39235 10.6419 2.12905C8.96971 1.97199 7.38761 2.32999 5.94524 3.22035C3.87464 4.48603 2.6055 6.29795 2.18976 8.69997C2.1505 8.92747 2.28099 8.8859 2.40109 8.88705C3.17136 8.88821 3.94162 8.88012 4.71189 8.89283C4.9417 8.89629 5.04217 8.83278 5.0976 8.5995C5.26274 7.90776 5.57569 7.27723 6.04571 6.74255C6.55036 6.16861 7.15087 5.70552 7.83106 5.35215Z" fill="#186AB4"/>
										<path d="M17.2532 13.0975C17.6181 12.4716 17.9681 12.2765 18.49 12.4081C19.146 12.5732 19.4832 13.1968 19.228 13.8239C19.0663 14.2223 18.8596 14.6023 18.6725 14.9903C18.1632 15.809 17.5916 16.5782 16.8848 17.2399C15.6319 18.412 14.1872 19.2342 12.5196 19.665C10.5206 20.1824 8.54588 20.105 6.6104 19.4017C3.90581 18.4189 1.98997 16.5758 0.806278 13.959C0.551063 13.3943 0.660771 12.7395 1.2786 12.4774C1.85716 12.2314 2.42303 12.4705 2.71981 13.1125C3.98319 15.8529 6.10459 17.4812 9.09904 17.845C11.4884 18.136 13.6075 17.4235 15.3847 15.7721C16.1989 15.0168 16.8502 14.1438 17.2532 13.0975Z" fill="#186AB4"/>
									</svg>
								OnHold</a>
                                </p>
						</div>
						<div class="col-lg-12 col-sm-12 address">
							<p class="location">Bankers</p>
							<p class="add email"><a href="mailto:bankers@instacrypto.com" target="_blank">bankers@instacrypto.com</a></p>
							<p class="add social-link whatsapp">
								<a href="https://api.whatsapp.com/send?phone=16476067937&text=&source=&data=" target="_blank">
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M10.0025 0H9.9975C4.48375 0 0 4.485 0 10C0 12.1875 0.705 14.215 1.90375 15.8612L0.6575 19.5763L4.50125 18.3475C6.0825 19.395 7.96875 20 10.0025 20C15.5162 20 20 15.5138 20 10C20 4.48625 15.5162 0 10.0025 0Z" fill="#4CAF50"/>
										<path d="M15.8212 14.1212C15.5799 14.8025 14.6224 15.3675 13.8587 15.5325C13.3362 15.6437 12.6537 15.7325 10.3562 14.78C7.41744 13.5625 5.52494 10.5762 5.37744 10.3825C5.23619 10.1887 4.18994 8.80123 4.18994 7.36623C4.18994 5.93123 4.91869 5.23248 5.21244 4.93248C5.45369 4.68623 5.85244 4.57373 6.23494 4.57373C6.35869 4.57373 6.46994 4.57998 6.56994 4.58498C6.86369 4.59748 7.01119 4.61498 7.20494 5.07873C7.44619 5.65998 8.03369 7.09498 8.10369 7.24248C8.17494 7.38998 8.24619 7.58998 8.14619 7.78373C8.05244 7.98373 7.96994 8.07248 7.82244 8.24248C7.67494 8.41248 7.53494 8.54248 7.38744 8.72498C7.25244 8.88373 7.09994 9.05373 7.26994 9.34748C7.43994 9.63498 8.02744 10.5937 8.89244 11.3637C10.0087 12.3575 10.9137 12.675 11.2374 12.81C11.4787 12.91 11.7662 12.8862 11.9424 12.6987C12.1662 12.4575 12.4424 12.0575 12.7237 11.6637C12.9237 11.3812 13.1762 11.3462 13.4412 11.4462C13.7112 11.54 15.1399 12.2462 15.4337 12.3925C15.7274 12.54 15.9212 12.61 15.9924 12.7337C16.0624 12.8575 16.0624 13.4387 15.8212 14.1212Z" fill="#FAFAFA"/>
									</svg>
							Whatsapp</a>
							</p>
							<p class="add social-link telegram">
								<a href="https://t.me/BankersChat" target="_blank">
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#039BE5"/>
										<path d="M4.57581 9.78338L14.2175 6.06588C14.665 5.90422 15.0558 6.17505 14.9108 6.85172L14.9116 6.85088L13.27 14.5851C13.1483 15.1334 12.8225 15.2667 12.3666 15.0084L9.86664 13.1659L8.66081 14.3276C8.52747 14.4609 8.41497 14.5734 8.15664 14.5734L8.33414 12.0292L12.9675 7.84338C13.1691 7.66588 12.9225 7.56588 12.6566 7.74255L6.93081 11.3476L4.46247 10.5776C3.92664 10.4076 3.91497 10.0417 4.57581 9.78338Z" fill="white"/>
									</svg>
								Telegram</a>
							</p>
							<p class="add social-link onhold">
								<a href="http://onhold.app/" target="_blank">
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M3.97049 1.99624C5.23849 1.08278 6.63235 0.466106 8.17288 0.171627C12.0589 -0.573232 16.17 1.15091 18.3284 4.4791C19.3042 5.98268 19.8978 7.62714 19.9717 9.4379C20.0098 10.3883 19.4612 10.9738 18.5039 10.9807C17.4484 10.9888 16.3917 10.99 15.3351 10.9946C14.9979 10.9888 14.6595 10.9888 14.3223 10.9761C13.6814 10.9519 13.1802 10.5281 13.0462 9.90214C12.9977 9.67579 12.9746 9.44252 12.9157 9.21848C12.5289 7.77496 11.2539 6.88459 9.72265 6.98044C8.32532 7.06821 7.14971 8.18608 6.98688 9.65039C6.90143 10.4114 6.30207 11.0108 5.48215 10.99C4.15296 10.9553 2.82145 10.9842 1.4911 10.9796C0.506035 10.9761 -0.0586717 10.3814 0.00484337 9.39171C0.0752874 8.29347 0.31549 7.23335 0.719677 6.20556C1.39756 4.48603 2.55007 3.13951 3.97049 1.99624ZM7.83106 5.35215C7.91998 5.31981 8.01005 5.28979 8.09782 5.25514C9.22954 4.80707 10.3832 4.74125 11.5369 5.13504C13.2552 5.72169 14.3823 6.89499 14.8997 8.64223C14.9436 8.79236 14.9829 8.89745 15.1861 8.89398C15.9887 8.88012 16.7913 8.88359 17.5951 8.89167C17.7879 8.89398 17.8237 8.82931 17.7902 8.64916C17.6343 7.80152 17.3745 6.98968 16.9495 6.2402C15.5568 3.78506 13.4504 2.39235 10.6419 2.12905C8.96971 1.97199 7.38761 2.32999 5.94524 3.22035C3.87464 4.48603 2.6055 6.29795 2.18976 8.69997C2.1505 8.92747 2.28099 8.8859 2.40109 8.88705C3.17136 8.88821 3.94162 8.88012 4.71189 8.89283C4.9417 8.89629 5.04217 8.83278 5.0976 8.5995C5.26274 7.90776 5.57569 7.27723 6.04571 6.74255C6.55036 6.16861 7.15087 5.70552 7.83106 5.35215Z" fill="#186AB4"/>
										<path d="M17.2532 13.0975C17.6181 12.4716 17.9681 12.2765 18.49 12.4081C19.146 12.5732 19.4832 13.1968 19.228 13.8239C19.0663 14.2223 18.8596 14.6023 18.6725 14.9903C18.1632 15.809 17.5916 16.5782 16.8848 17.2399C15.6319 18.412 14.1872 19.2342 12.5196 19.665C10.5206 20.1824 8.54588 20.105 6.6104 19.4017C3.90581 18.4189 1.98997 16.5758 0.806278 13.959C0.551063 13.3943 0.660771 12.7395 1.2786 12.4774C1.85716 12.2314 2.42303 12.4705 2.71981 13.1125C3.98319 15.8529 6.10459 17.4812 9.09904 17.845C11.4884 18.136 13.6075 17.4235 15.3847 15.7721C16.1989 15.0168 16.8502 14.1438 17.2532 13.0975Z" fill="#186AB4"/>
									</svg>
								OnHold</a>
							</p>
						</div>
					</div>
					<div class="col-lg-5 col-sm-12 column-2">
						<div class="form-content" data-aos="fade-up" data-aos-duration="1000">
							<div class="contact-form">
								<form action="#">
									<div class="form-header">
						
										<p class="f-subtitle">InstaCrypto Bot</p>
										<p class="f-content">Powered By Chats.io</p>
									</div>
									<div class="form-body">
										<div class="f-name">
											<label>Name</label><br/>
											<input type="text"/>
										</div>
										<div class="f-mail">
											<label>Email</label><br/>
											<input type="email"/>
										</div>
										<div class="f-subject">
											<label>Subject</label><br/>
											<input type="text"/>
										</div>
										<div class="f-message">
											<label>Message</label><br/>
											<textarea></textarea>
										</div>
										<div class="f-submit">
											<input type="submit"/>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="sec-div13">
		<div class="container app-section">
			<div class="contents">
				<div class="row">
					<div class="column-1">
						<div class="app-section">
							<div class="title-text">
								<p class="head">Download The App</p>
								<div class="foot-social">
									<p class="foot-app-link" id="app-link-foot">
                                    <a class='nav-link app-link android' href='"+android+"' target='_blank'><i class='fa fa-android' aria-hidden='true'></i> Android</a><a class='nav-link app-link ios' href='http://"+ios+"' target='_blank'><i class='fa fa-apple' aria-hidden='true'></i> IOS</a>
            <a class='android' href='"+android+"' target='_blank'><i class='fa fa-android' aria-hidden='true'></i> Android</a><a class='ios' href='http://"+ios+"' target='_blank'><i class='fa fa-apple' aria-hidden='true'></i> IOS</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
        
		<div class="copyright-menu-btm">
			
			
				<div id="google_translate_element"></div>
				
					<a href="privacy-policy.html">Privacy Policy</a>
					<a href="terms-conditions.html">Terms & Conditions</a>
		
		</div>
        </div>
    </>
    )
}
